import React from "react";
import ReactDOM from "react-dom";
import "./CSS/styling.css";
import "./CSS/styling_big_screen.css";
import App from "./App";
import * as serviceWorker from "./Setup_JS_FILES/serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import firebase from "firebase/app";
import "firebase/database";

var config = {
  apiKey: "AIzaSyBVdd6YjprvexpP2FWF4Horp6IP_e-lh0M",
  authDomain: "luup-4a75f.firebaseapp.com",
  databaseURL: "https://luup-4a75f.firebaseio.com",
  projectId: "luup-4a75f",
  storageBucket: "luup-4a75f.appspot.com",
  messagingSenderId: "917644898278",
};

firebase.initializeApp(config);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
