import _ from "lodash";
import React, { Component } from "react";
import logo from "./IMG/logo.svg";
import googleReviews from "./IMG/google.png";

import Lottie from "react-lottie";
import { Button } from "react-bootstrap";
import moment from "moment";
import moment_time_zone from "moment-timezone";
import QrReader from "react-qr-reader";
import { Spring } from "react-spring/renderprops";
import { confirmAlert } from "react-confirm-alert";
import { Progress } from "semantic-ui-react";

import firebase from "firebase/app";
import "firebase/database";

import * as amplitude from "amplitude-js";

amplitude.getInstance().init("a5e363b577b06e43fa6faecf5ac3dbe0");

const mainwebsite = "https://survey.getluup.com/";
const redirectLink = "app.getluup.com/"; //"luuptesting.page.link/";
const localhostLink = "http://localhost:3000/";
const surveywebsite = "https://survey.getluup.com/"; //"https://testsurvey-d50d1.firebaseapp.com/";
var array = [];

class App extends Component {
  constructor() {
    super();
    this.handleButtonPressed = this.handleButtonPressed.bind(this);
    this.handleNextButtonPressed = this.handleNextButtonPressed.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.state = {
      question: 0,
      SurveyQuestions: {},
      text: "",
      choiceSelected: null,
      user_answer: "",
      array: [],
      display_string: "",
      buttonWidth: 0,
      buttonHeight: 0,
      longPress: false,
      isStopped: false,
      isPaused: false,
      button_pressed_in: false,
      show_content: null,
      show_first_page: true,
      email_entered: false,
      data: {},
      survey_id: "",
      emailAddress: "",
      points: "",
      show_main_page_to_scan: false,
      qr_code_camera_activate: false,
      expired: false,
      question_state: "",
      answer_state: "",
      type_of_question_state: "",
      department_database: "",
      survey_name_database: "",
      company_or_institution_name_database: "",
      random_number_generated: "",
      unique_entry: true,
      email_required: false,
      show_result: false,
      instant_draw_number_result: "",
      in_person_draw: "",
      instant_draw_number: "",
      user_prize_draw: "",
      positive_feedback: "",
      instant_draw: "",
      conditional_choice_selected: null,
      exit_questionnaire: false,
      width: 0,
      height: 0,
    };

    // this.initial_load();
  }
  componentDidMount() {
    this.initial_load();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  initial_load() {
    var str = window.location.href;

    let position = str.indexOf("=");
    var response = str.split("?survey=");

    var res = str.substr(position + 1);
    if (response[1] !== undefined) {
      res = res.replace(".", "_");
      res = res.replace("#", "_");
      res = res.replace("$", "_");
      res = res.replace("[", "_");
      res = res.replace("]", "_");
      var title = res;

      firebase
        .database()
        .ref(`Admin/random_number_generating_range/`)
        .once(
          "value",
          function (generate_number) {
            var in_person_draw = generate_number.val().in_person_draw;
            var instant_draw = generate_number.val().instant_draw;

            var instant_draw_number = generate_number.val().instant_draw_number;
            var user_prize_draw = generate_number.val().user_prize_draw;

            this.setState({
              in_person_draw,
              instant_draw,
              instant_draw_number,
              user_prize_draw,
            });
          }.bind(this)
        );

      firebase
        .database()
        .ref(`Admin/Points/`)
        .once("value", (snapshot) => {
          var points = snapshot.val().points_per_survey;
          this.setState({ points });

          firebase
            .database()
            .ref(`QR_Code/survey_id_for_QR_creation/${title}/`)
            .once("value", (snapshot) => {
              var department, survey_name, company_or_institution_name;
              if (snapshot.val() !== null) {
                department = snapshot.val().data.department;
                survey_name = snapshot.val().data.survey_name;
                company_or_institution_name =
                  snapshot.val().data.company_or_institution_name;
              } else {
                department = "nothing";
                survey_name = "nothing";
                company_or_institution_name = "nothing";
              }

              this.setState({
                department_database: department,
                survey_name_database: survey_name,
                company_or_institution_name_database:
                  company_or_institution_name,
              });

              firebase
                .database()
                .ref(
                  `QR_Code/questionnaire/${company_or_institution_name}/${department}/${survey_name}/${title}/`
                )
                .once("value", (snapshot) => {
                  if (snapshot.val() !== null) {
                    var SurveyQuestions = snapshot.val().questions;
                    var data = snapshot.val();
                    this.setState({
                      SurveyQuestions,
                      data,
                      survey_id: title,
                    });
                    var questionsArray = _.map(SurveyQuestions, (val, uid) => {
                      return { ...val, uid };
                    });

                    var display_string = {
                      current: this.state.question + 1,
                      total: questionsArray.length,
                    };

                    var date = moment(
                      moment_time_zone().tz("America/Toronto").format()
                    ).valueOf();

                    if (moment(date).isAfter(data.survey_expiry) === false) {
                      amplitude.getInstance().logEvent("Survey_Opened", {
                        company_or_institution_name,
                        department,
                        survey_name,
                      });

                      this.setState({ display_string });
                      this.setState({ show_content: true });
                    } else {
                      amplitude.getInstance().logEvent("Expired_Survey", {
                        company_or_institution_name,
                        department,
                        survey_name,
                      });

                      this.setState({
                        show_content: false,
                        expired: true,
                      });
                    }
                  } else {
                    this.setState({ show_content: false });
                  }
                });
            });
        });
    } else {
      if (response[0] === localhostLink || response[0] === surveywebsite) {
        this.setState({ show_main_page_to_scan: true, show_content: true });

        amplitude.getInstance().logEvent("Scan_Barcode");
      } else {
        this.setState({ show_content: false });
      }
    }
  }

  handleScan = (data) => {
    if (data) {
      var response = data.split(redirectLink);
      if (response[1] !== undefined) {
        var res = response[1];
        res = res.replace(".", "_");
        res = res.replace("#", "_");
        res = res.replace("$", "_");
        res = res.replace("[", "_");
        res = res.replace("]", "_");
        var title = res;

        firebase
          .database()
          .ref(`QR_Code/survey_id_search/${title}/`)
          .once("value", (snapshot) => {
            if (snapshot.val() === null) {
              alert("Wrong QR Code!");
            } else {
              var str = window.location.href;

              window.location = str + "?survey=" + snapshot.val().surveyID;
            }
          });
      } else {
        alert("Wrong Code!");
      }
    }
  };
  handleError = (err) => {
    alert(err);
  };

  handleButtonPressed({ question, answer, type_of_question, uid }) {
    this.setState({
      choiceSelected: uid,
      answer_state: answer,
    });
  }

  handleNextButtonPressed({ question, type_of_question }) {
    const { answer_state, user_answer } = this.state;
    var answer;

    if (
      type_of_question === "MCQ" ||
      type_of_question === "ConditionalMCQ" ||
      type_of_question === "ConditionalCSAT" ||
      type_of_question === "ConditionalNPS"
    ) {
      answer = answer_state;
    } else {
      answer = user_answer;
    }
    if (type_of_question === "Open Field" && answer.length === 0) {
      alert("Please enter a comment before proceeding forward.");
    } else if (
      (type_of_question === "MCQ" && answer.length === 0) ||
      (type_of_question === "NPS" && answer.length === 0) ||
      (type_of_question === "CSAT" && answer.length === 0) ||
      (type_of_question === "ConditionalMCQ" && answer.length === 0) ||
      (type_of_question === "ConditionalCSAT" && answer.length === 0) ||
      (type_of_question === "ConditionalNPS" && answer.length === 0)
    ) {
      alert("Please select your choice before proceeding forward.");
    } else {
      var questionsArray = _.map(this.state.SurveyQuestions, (val, uid) => {
        return { ...val, uid };
      });
      if (this.state.question < questionsArray.length) {
        array.push({ type_of_question, question, answer });

        var questionNumber = this.state.question;
        var add = questionNumber + 1;

        ////////////////////////////////
        var questionsArray_temp = _.map(
          this.state.SurveyQuestions,
          (val, uid) => {
            return { ...val, uid };
          }
        );
        var temp_questionaire = questionsArray_temp[questionNumber];

        if (temp_questionaire[1].questionnaire_type !== undefined) {
          if (
            temp_questionaire[1].questionnaire_type === "ConditionalMCQ" ||
            temp_questionaire[1].questionnaire_type === "ConditionalCSAT" ||
            temp_questionaire[1].questionnaire_type === "ConditionalNPS"
          ) {
            var next_temp_questionaire = questionsArray_temp[add];
            if (
              next_temp_questionaire[this.state.conditional_choice_selected]
                .skip === true
            ) {
              add = add + 1;
            }
          }
        }

        this.setState({
          question: add,
          choiceSelected: null,
          answer_state: "",
          user_answer: "",
        });
        if (add + 1 <= questionsArray.length) {
          var display_string = {
            current: add + 1,
            total: questionsArray.length,
          };
          this.setState({ display_string, button_pressed_in: false });
        }
      }

      if (add === this.state.SurveyQuestions.length) {
        const {
          company_or_institution_name,
          email_required,
          general_prize_draw,
          survey_expiry,
          survey_name,
          type_of_email,
          user_prize_draw,
          draw,
          in_person_draw,
          survey_type,
          instant_draw_number,
          type_of_questionnaire,
          instant_draw,
          giftcard_with_each_feedback,
        } = this.state.data;
        var { survey_id, emailAddress, unique_entry } = this.state;

        if (unique_entry === false) {
          emailAddress = "Email Not Required";
        }
        if (this.state.email_required === true) {
          emailAddress = this.state.emailAddress;
        }
        var questionnaire_result = array;

        var date_local = moment_time_zone()
          .tz("America/Toronto")
          .format("MM/DD/YYYY");
        var time_local = " 12:00";
        var Date_Time = date_local + time_local;
        var time = moment_time_zone
          .tz(Date_Time, "MM/DD/YYYY H:m", "America/Toronto")
          .valueOf();

        var survey_submitted_on = moment_time_zone()
          .tz("America/Toronto")
          .format();
        var {
          department_database,
          survey_name_database,
          company_or_institution_name_database,
        } = this.state;
        var transaction_info_array = [];

        if (survey_type === "retail") {
          transaction_info_array = [
            {
              company_or_institution_name,
              email_required,
              survey_expiry,
              survey_name,
              type_of_email,
              survey_id,
              draw,
              in_person_draw,
              department: department_database,
            },
          ];
        } else {
          transaction_info_array = [
            {
              company_or_institution_name,
              email_required,
              general_prize_draw,
              survey_expiry,
              survey_name,
              type_of_email,
              user_prize_draw,
              survey_id,
              draw,
              in_person_draw,
              department: department_database,
            },
          ];
        }
        var transaction_info = transaction_info_array[0];

        //   if (survey_type !== undefined && survey_type === "retail") {

        if (survey_type === "retail") {
          if (instant_draw === true) {
            //  this.state.instant_draw_number;
            //  this.state.user_prize_draw;
            //  this.state.instant_draw;

            firebase
              .database()
              .ref(
                `QR_Code/Retail/unique_numbers/${company_or_institution_name_database}/${department_database}/${survey_name_database}/${survey_id}`
              )

              .once("value", (snapshot) => {
                var uniquie = _.map(snapshot.val(), (val, uid) => {
                  return { ...val };
                });

                var randomNumber;
                var checking;
                var found;

                do {
                  randomNumber = Math.floor(
                    Math.random() * Math.floor(this.state.instant_draw)
                  );
                  checking = randomNumber;
                  found = uniquie.find((element) => {
                    return checking === element.randomNumber;
                  });
                } while (found !== undefined);

                if (found === undefined) {
                  this.setState({ random_number_generated: randomNumber });

                  if (randomNumber === this.state.instant_draw_number) {
                    this.setState({
                      instant_draw_number_result: "WINNER",
                    });

                    firebase
                      .database()
                      .ref()
                      .child(`QR_Code/Retail/winners/`)
                      .push({
                        emailAddress,
                        survey_submitted_on,
                        questionnaire_result,
                        transaction_info,
                        user: "NOT A USER",
                        user_random_number: randomNumber,
                        company_or_institution_name_database,
                        department_database,
                        survey_name_database,
                        survey_id,
                      });
                  } else {
                    this.setState({
                      instant_draw_number_result: "LOSER",
                    });
                  }

                  firebase
                    .database()
                    .ref()
                    .child(
                      `QR_Code/Survey_Submitted/${company_or_institution_name_database}/${department_database}/All_Responses_Combined/all_questionnaire/All`
                    )
                    .push({
                      emailAddress,
                      survey_submitted_on,
                      questionnaire_result,
                      transaction_info,
                      user: "NOT A USER",
                    });

                  firebase
                    .database()
                    .ref()
                    .child(
                      `QR_Code/Retail/unique_numbers/${company_or_institution_name_database}/${department_database}/${survey_name_database}/${survey_id}`
                    )
                    .push({
                      emailAddress,
                      randomNumber,
                    });

                  //MAYBE WANT TO REMOVE THIS ONE
                  firebase
                    .database()
                    .ref()
                    .child(
                      `QR_Code/Retail/unique/${company_or_institution_name_database}/${department_database}/${survey_name_database}/${survey_id}/${time}/`
                    )
                    .push({
                      emailAddress,
                      randomNumber,
                    });
                }
              });
          } else {
            if (giftcard_with_each_feedback === true) {
              firebase
                .database()
                .ref()
                .child(
                  `QR_Code/Survey_Submitted/${company_or_institution_name_database}/${department_database}/All_Responses_Combined/all_questionnaire/All`
                )
                .push({
                  emailAddress,
                  survey_submitted_on,
                  questionnaire_result,
                  transaction_info,
                  user: "NOT A USER",
                  giftcard_sent: false,
                });
            } else {
              firebase
                .database()
                .ref()
                .child(
                  `QR_Code/Survey_Submitted/${company_or_institution_name_database}/${department_database}/All_Responses_Combined/all_questionnaire/All`
                )
                .push({
                  emailAddress,
                  survey_submitted_on,
                  questionnaire_result,
                  transaction_info,
                  user: "NOT A USER",
                });
            }
          }
        } else {
          if (type_of_questionnaire !== undefined) {
            survey_name_database = questionnaire_result[0].answer;
            var new_array = [];
            for (var h = 0; h < questionnaire_result.length - 1; h++) {
              new_array[h] = questionnaire_result[h + 1];
            }
            questionnaire_result = new_array;

            var key_push = firebase
              .database()
              .ref(
                `QR_Code/Survey_Submitted/${company_or_institution_name_database}/${department_database}/All_Responses_Combined/all_questionnaire/All`
              )
              .push().key;

            firebase
              .database()
              .ref(
                `QR_Code/Survey_Submitted/${company_or_institution_name_database}/${department_database}/All_Responses_Combined/all_questionnaire/All/${key_push}`
              )
              .update({
                emailAddress,
                survey_submitted_on,
                questionnaire_result,
                transaction_info,
                user: "NOT A USER",
              })
              .then(() => {
                firebase
                  .database()
                  .ref(
                    `QR_Code/Survey_Submitted/${company_or_institution_name_database}/${department_database}/All_Responses_Combined/all_questionnaire/All/${key_push}/transaction_info`
                  )
                  .update({
                    branch_survey_name: survey_name_database,
                    type_of_questionnaire: "branch",
                  });
              });
          } else {
            firebase
              .database()
              .ref()
              .child(
                `QR_Code/Survey_Submitted/${company_or_institution_name_database}/${department_database}/All_Responses_Combined/all_questionnaire/All`
              )
              .push({
                emailAddress,
                survey_submitted_on,
                questionnaire_result,
                transaction_info,
                user: "NOT A USER",
              });
          }

          if (unique_entry === true) {
            if (draw === true && in_person_draw === true) {
              firebase
                .database()
                .ref(
                  `QR_Code/unique/${company_or_institution_name_database}/${department_database}/${survey_name_database}/${survey_id}/current/`
                )

                .once(
                  "value",
                  function (snapshot) {
                    var uniquie = _.map(snapshot.val(), (val, uid) => {
                      return { ...val };
                    });

                    var randomNumber;

                    var checking;
                    var found;

                    do {
                      randomNumber = Math.floor(
                        Math.random() * Math.floor(this.state.in_person_draw)
                      );
                      checking = randomNumber;
                      found = uniquie.find((element) => {
                        return checking === element.randomNumber;
                      });
                    } while (found !== undefined);

                    if (found === undefined) {
                      this.setState({ random_number_generated: randomNumber });

                      firebase
                        .database()
                        .ref()
                        .child(
                          `QR_Code/unique/${company_or_institution_name_database}/${department_database}/${survey_name_database}/${survey_id}/current/`
                        )
                        .push({
                          emailAddress,
                          randomNumber,
                        });
                    } else {
                      //  console.log("generate a new number");
                    }
                  }.bind(this)
                );
            } else {
              firebase
                .database()
                .ref()
                .child(
                  `QR_Code/unique/${company_or_institution_name_database}/${department_database}/${survey_name_database}/${survey_id}/current/`
                )
                .push({
                  emailAddress,
                });
            }
          }
        }
        amplitude.getInstance().logEvent("QR_Survey_Completed_From_Web", {
          company_or_institution_name:
            this.state.data.company_or_institution_name,
          department: this.state.data.department,
          survey_name: this.state.data.survey_name,
        });
      }
      window.scrollTo(0, 0);
    }
  }

  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
    this.setState({ button_pressed_in: false, choiceSelected: null });

    if (this.state.longPress === true) {
      this.setState({ longPress: false });
    } else {
      alert("Please hold and press to continue.");
    }
  }

  handleEmail(event) {
    this.setState({ emailAddress: event.target.value });
  }

  render_question() {
    const array = this.state.SurveyQuestions[this.state.question];
    const data = _.map(array, (val, uid) => {
      return { ...val, uid };
    });

    var questionsArray = _.map(this.state.SurveyQuestions, (val, uid) => {
      return { ...val, uid };
    });

    var question = "";

    if (array !== undefined) {
      //ADD HERE
      question = data[0].question;

      if (this.state.question > 0) {
        var temp_questionaire = questionsArray[this.state.question - 1];

        if (temp_questionaire[1].questionnaire_type !== undefined) {
          if (
            temp_questionaire[1].questionnaire_type === "ConditionalMCQ" ||
            temp_questionaire[1].questionnaire_type === "ConditionalCSAT" ||
            temp_questionaire[1].questionnaire_type === "ConditionalNPS"
          ) {
            question = data[this.state.conditional_choice_selected][0].question;
          }
        }
      }
    }
    if (this.state.question < questionsArray.length) {
      return (
        <div className="padding-question">
          <p className="question_font">{question}</p>
        </div>
      );
    }
    return null;
  }
  renderCircle_checked({ uid }) {
    if (this.state.choiceSelected === uid) {
      return <span id={uid} className="dot" />;
    }
    return null;
  }

  render_choices() {
    const array = this.state.SurveyQuestions[this.state.question];
    const data = _.map(array, (val, uid) => {
      return { ...val };
    });
    var questionsArray = _.map(this.state.SurveyQuestions, (val, uid) => {
      return { ...val, uid };
    });

    var questionnaire_type_conditional = "";
    var choices_conditional = {};
    var question_type = "";

    if (array !== undefined) {
      var question = data[0].question;
      question_type = data[1].questionnaire_type;

      if (this.state.question > 0) {
        var temp_questionaire = questionsArray[this.state.question - 1];
        if (temp_questionaire[1].questionnaire_type !== undefined) {
          if (
            temp_questionaire[1].questionnaire_type === "ConditionalMCQ" ||
            temp_questionaire[1].questionnaire_type === "ConditionalCSAT" ||
            temp_questionaire[1].questionnaire_type === "ConditionalNPS"
          ) {
            question = data[this.state.conditional_choice_selected][0].question;
            questionnaire_type_conditional =
              data[this.state.conditional_choice_selected][1]
                .questionnaire_type;
            question_type = questionnaire_type_conditional;
            choices_conditional =
              data[this.state.conditional_choice_selected][2];
          }
        }
      }

      if (this.state.question < questionsArray.length) {
        if (
          data[1].questionnaire_type === "Open Field" ||
          questionnaire_type_conditional === "Open Field"
        ) {
          return (
            <div>
              <div className="form-group paddingForm">
                <label
                  className="fontStyling_choice"
                  htmlFor={this.state.question}
                >
                  Type your response:
                </label>
                <textarea
                  className="form-control"
                  id={this.state.question}
                  value={this.state.user_answer}
                  rows="3"
                  onChange={(event) => {
                    this.setState({ user_answer: event.target.value });
                  }}
                />
              </div>
              <div style={{ paddingTop: 10 }}>
                <Button
                  variant="outline-light"
                  onClick={() => {
                    this.handleNextButtonPressed({
                      question,
                      type_of_question: question_type, //data[1].questionnaire_type, //"Open Field",
                    });
                  }}
                  style={{
                    background: "#301b92",
                    color: "white",
                    paddingRight: 30,
                    paddingLeft: 30,
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontFamily: "Quicksand",
                    fontSize: 16.5,
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          );
        }

        var data2 = _.map(data[2], (val, uid) => {
          return { ...val, uid };
        });

        if (this.state.question > 0) {
          var temp_questionaire = questionsArray[this.state.question - 1];

          if (temp_questionaire[1].questionnaire_type !== undefined) {
            if (
              temp_questionaire[1].questionnaire_type === "ConditionalMCQ" ||
              temp_questionaire[1].questionnaire_type === "ConditionalNPS" ||
              temp_questionaire[1].questionnaire_type === "ConditionalCSAT"
            ) {
              // console.log(choices_conditional);
              data2 = _.map(choices_conditional, (val, uid) => {
                return { ...val, uid };
              });
            }
          }
        }

        var choices = data2.map((info) => (
          <div
            onClick={() => {
              if (
                question_type === "ConditionalMCQ" ||
                question_type === "ConditionalNPS" ||
                question_type === "ConditionalCSAT"
              ) {
                this.setState({ conditional_choice_selected: info.uid });
              }
              this.handleButtonPressed({
                question,
                answer: info.choice,
                type_of_question: data[1].questionnaire_type, //"MCQ",
                uid: info.uid,
              });
            }}
            key={info.uid}
            id={info.uid}
            style={{ padding: 4 }}
          >
            <Button
              variant="outline-light"
              style={{ paddingTop: 15, paddingBottom: 15 }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-2">
                    <div className="vertical-center">
                      <div className="circle_outline">
                        {this.renderCircle_checked({
                          uid: info.uid,
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-10">
                    <div>
                      <p id={info.uid} className="fontStyling_choice">
                        {info.choice}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Button>
          </div>
        ));

        return (
          <div>
            <div>{choices}</div>
            <div style={{ paddingTop: 10 }}>
              <Button
                variant="outline-light"
                onClick={() => {
                  this.handleNextButtonPressed({
                    question,
                    type_of_question: question_type, //data[1].questionnaire_type, //"MCQ",
                  });
                }}
                style={{
                  background: "#301b92",
                  color: "white",
                  paddingRight: 30,
                  paddingLeft: 30,
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontFamily: "Quicksand",
                  fontSize: 16.5,
                }}
              >
                Next
              </Button>
            </div>
          </div>
        );
      }
      return null;
    }
    return null;
  }

  check_question_details() {
    const array = this.state.SurveyQuestions[this.state.question];
    const data = _.map(array, (val, uid) => {
      return { ...val };
    });
    var questionsArray = _.map(this.state.SurveyQuestions, (val, uid) => {
      return { ...val, uid };
    });

    var questionnaire_type_conditional = "";
    var choices_conditional = {};
    var question_type = "";

    if (array !== undefined) {
      var question = data[0].question;
      question_type = data[1].questionnaire_type;

      if (this.state.question > 0) {
        var temp_questionaire = questionsArray[this.state.question - 1];
        if (temp_questionaire[1].questionnaire_type !== undefined) {
          if (
            temp_questionaire[1].questionnaire_type === "ConditionalMCQ" ||
            temp_questionaire[1].questionnaire_type === "ConditionalCSAT" ||
            temp_questionaire[1].questionnaire_type === "ConditionalNPS"
          ) {
            question = data[this.state.conditional_choice_selected][0].question;
            questionnaire_type_conditional =
              data[this.state.conditional_choice_selected][1]
                .questionnaire_type;
            question_type = questionnaire_type_conditional;
            choices_conditional =
              data[this.state.conditional_choice_selected][2];
          }
        }
      }

      return question_type;
    }
    return null;
  }

  render_randomnumber() {
    const { draw, in_person_draw } = this.state.data;
    if (draw === true && in_person_draw === true) {
      return (
        <div>
          <p className="fontStyling_ending" style={{ color: "red" }}>
            Your entry number is {this.state.random_number_generated}
          </p>
          <p className="fontStyling_ending">Please screenshot this page.</p>

          <div style={{ paddingRight: 15, paddingLeft: 15 }}>
            {this.render_last_screen_button()}
          </div>
        </div>
      );
    }
    return null;
  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  render_last_screen_button() {
    return <div></div>;
    /* const { instant_draw_number, survey_type, user_plan } = this.state.data;

    if (survey_type === "retail" && user_plan === "MidRange") {
      return (
        <a
          className="text-center"
          onClick={() => {
            window.open(this.state.data.google_review_link, "_blank");
          }}
        >
          <img src={googleReviews} className="googleimg" />
        </a>
      );
    }

    return (
      <a
        onClick={() => {
          amplitude.getInstance().logEvent("Download_App_Button_Pressed", {
            company_or_institution_name: this.state.data
              .company_or_institution_name,
            department: this.state.data.department,
            survey_name: this.state.data.survey_name,
          });

          let os = this.getMobileOperatingSystem();
          if (os === "Android") {
            window.open(
              "https://play.google.com/store/apps/details?id=com.luup.application",
              "_blank"
            );
          } else if (os === "iOS") {
            window.open(
              "https://itunes.apple.com/ca/app/luup-rewards-for-feedback/id1459323700?mt=8",
              "_blank"
            );
          } else {
            window.open("http://www.getluup.com", "_blank");
          }
        }}
      >
        <div className="download_app_button">
          <p className="download_app_button_font">
            Give Feedback. Get Gift cards. Download App!
          </p>
        </div>
      </a>
    );*/
  }
  render_last_screen() {
    const { draw, in_person_draw, instant_draw_number, survey_type } =
      this.state.data;

    var questionsArray = _.map(this.state.SurveyQuestions, (val, uid) => {
      return { ...val, uid };
    });

    //  if (instant_draw_number !== undefined && this.state.show_result === true) {
    if (survey_type === "retail") {
      //enter something
      if (this.state.instant_draw_number_result === "LOSER") {
        return (
          <div className="luup-5">
            <div className="center">
              <div className="noselect">
                <div className="container text-center">
                  <div
                    style={{
                      paddingTop: 25,
                      paddingBottom: 25,
                    }}
                  >
                    <div>
                      <div className="text-center">
                        <h3 className="heading">Aww shucks!</h3>

                        <Lottie
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: require("./JSON/sad.json"),
                            rendererSettings: {
                              preserveAspectRatio: "xMidYMid slice",
                            },
                          }}
                          width="50%"
                        />

                        <p
                          className="text_under_headiong"
                          style={{ fontWeight: "bold" }}
                        >
                          You didn’t win this time, but try again soon - you
                          never know when it’s your lucky day!
                        </p>

                        <div className="padding-top">
                          {this.render_last_screen_button()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      if (this.state.instant_draw_number_result === "WINNER") {
        return (
          <div className="luup-5">
            <div className="center">
              <div className="noselect">
                <div className="container text-center">
                  <div
                    style={{
                      paddingTop: 25,
                      paddingBottom: 25,
                    }}
                  >
                    <div>
                      <div className="text-center">
                        <h3 className="heading">CONGRATULATIONS!</h3>

                        <Lottie
                          options={{
                            loop: false,
                            autoplay: true,
                            animationData: require("./JSON/trophy.json"),
                            rendererSettings: {
                              preserveAspectRatio: "xMidYMid slice",
                            },
                          }}
                          width="50%"
                        />

                        <p
                          className="text_under_headiong"
                          style={{ fontWeight: "bold" }}
                        >
                          WOOHOO! You’re the lucky winner of our Instant Draw
                          for ${this.state.user_prize_draw}! We’ll email your
                          winnings at {this.state.emailAddress} within the next
                          24 hours
                        </p>

                        <div className="padding-top">
                          {this.render_last_screen_button()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      if (this.state.SurveyQuestions.length !== undefined) {
        if (this.state.question < questionsArray.length) {
          return null;
        }

        if (draw === false && in_person_draw === false) {
          return (
            <div className="luup-5">
              <div className="center">
                <div className="noselect">
                  <div className="container text-center">
                    <div
                      style={{
                        paddingTop: 25,
                        paddingBottom: 25,
                      }}
                    >
                      <div>
                        <div className="text-center">
                          <h3 className="heading">
                            Thank you for your feedback!
                          </h3>

                          <Lottie
                            options={{
                              loop: false,
                              autoplay: true,
                              animationData: require("./JSON/success.json"),
                              rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                              },
                            }}
                            width="55%"
                          />

                          <div className="padding-top">
                            {this.render_last_screen_button()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }

        return (
          <div className="luup-5">
            <div className="center">
              <div className="noselect">
                <div className="container text-center">
                  <div
                    style={{
                      paddingTop: 25,
                      paddingBottom: 25,
                    }}
                  >
                    <div>
                      <h3 className="heading">Thank you for your feedback!</h3>
                      <div className="text-center">
                        <Lottie
                          options={{
                            loop: false,
                            autoplay: true,
                            animationData: require("./JSON/success.json"),
                            rendererSettings: {
                              preserveAspectRatio: "xMidYMid slice",
                            },
                          }}
                          width="55%"
                        />

                        <div className="padding-top">
                          {this.render_randomnumber()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }
  }
  button_pressed() {
    var questionsArray = _.map(this.state.SurveyQuestions, (val, uid) => {
      return { ...val, uid };
    });
    if (this.state.question < questionsArray.length) {
      var questionNumber = this.state.question;
      var add = questionNumber + 1;
      this.setState({
        question: add,
      });
      if (add + 1 <= questionsArray.length) {
        var right_display = add + 1 + "/" + questionsArray.length;
        var display_string = String(right_display);
        this.setState({ display_string });
      }
    }
  }

  handleSubmit_with_email(event) {
    event.preventDefault();

    const { emailAddress, survey_id } = this.state;
    const { type_of_email, email_required, survey_type } = this.state.data;
    var {
      department_database,
      survey_name_database,
      company_or_institution_name_database,
    } = this.state;

    if (survey_type !== undefined && survey_type === "retail") {
      var date_local = moment_time_zone()
        .tz("America/Toronto")
        .format("MM/DD/YYYY");

      var time_local = " 12:00"; //" 00:00";
      var Date_Time = date_local + time_local;
      var time = moment(Date_Time, "MM/DD/YYYY H:mm").valueOf();

      firebase
        .database()
        .ref(
          `QR_Code/Retail/unique/${company_or_institution_name_database}/${department_database}/${survey_name_database}/${survey_id}/${time}/`
        )

        .once(
          "value",
          function (snappy) {
            var uniquie = _.map(snappy.val(), (val, uid) => {
              return { ...val };
            });

            var checking = emailAddress;
            var found = uniquie.find((element) => {
              return checking === element.emailAddress;
            });

            if (found === undefined) {
              this.setState({ email_entered: true });
            } else {
              alert(
                "Looks like you’ve already provided your feedback today. You can only complete 1 survey per day for this location."
              );
            }
          }.bind(this)
        );
    } else {
      firebase
        .database()
        .ref(
          `QR_Code/unique/${company_or_institution_name_database}/${department_database}/${survey_name_database}/${survey_id}/current/`
        )

        .once(
          "value",
          function (snapshot) {
            var uniquie = _.map(snapshot.val(), (val, uid) => {
              return { ...val };
            });

            var checking = emailAddress;
            var found = uniquie.find((element) => {
              return checking === element.emailAddress;
            });

            if (found === undefined) {
              if (email_required === true) {
                var str = emailAddress;
                var response = str.split("@");

                if (type_of_email === response[1]) {
                  this.setState({ email_entered: true });
                } else {
                  confirmAlert({
                    customUI: ({ onClose }) => {
                      return (
                        <div
                          className="alert react-confirm-alert-body"
                          role="alert"
                        >
                          <p style={{ fontSize: 18, textAlign: "center" }}>
                            Please use email ending in
                            <span style={{ fontWeight: "bold" }}>
                              "@{type_of_email}"
                            </span>
                          </p>

                          <div style={{ paddingBottom: 5 }}>
                            <Button
                              onClick={() => {
                                onClose();
                              }}
                              style={{
                                backgroundColor: "#301B92",
                                borderWidth: 0,
                                borderRadius: 50,
                              }}
                            >
                              Re-enter "@{type_of_email}" email address
                            </Button>
                          </div>
                          <div style={{ paddingTop: 5 }}>
                            <Button
                              onClick={() => {
                                this.setState({ email_entered: true });
                                onClose();
                              }}
                              style={{
                                backgroundColor: "#fff",
                                color: "#37474F",
                                borderWidth: 0,
                                borderRadius: 50,
                              }}
                            >
                              I don't have a {type_of_email} email address
                            </Button>
                          </div>
                        </div>
                      );
                    },
                  });
                }
              } else {
                this.setState({ email_entered: true });
              }
            } else {
              alert("You have already filled out this survey.");
            }
          }.bind(this)
        );
    }
  }

  print_email_bottom_line() {
    const {
      draw,
      in_person_draw,
      email_required,
      instant_draw_number,
      survey_type,
      instant_draw,
    } = this.state.data;

    if (survey_type === "retail") {
      if (instant_draw === true) {
        return (
          <div className="sub-heading-padding">
            <p className="sub-heading">
              We use this email to only verify, ensure unique entry, and send
              your giftcard if you win.
            </p>
          </div>
        );
      } else {
        return (
          <div className="sub-heading-padding">
            <p className="sub-heading">
              We send your gift card to this email address.
            </p>
          </div>
        );
      }
    } else {
      if (email_required === true) {
        if (draw === true && in_person_draw === false) {
          return (
            <div className="sub-heading-padding">
              <p className="sub-heading">
                We use this email to only verify, ensure unique entry, and send
                your giftcard if you win.
              </p>
            </div>
          );
        } else {
          return (
            <div className="sub-heading-padding">
              <p className="sub-heading">
                We use this email to only verify, and to ensure unique entry.
              </p>
            </div>
          );
        }
      } else {
        if (draw === true && in_person_draw === false) {
          return (
            <div className="sub-heading-padding">
              <p className="sub-heading">
                We use this email to only verify, ensure unique entry, and send
                your giftcard if you win.
              </p>
            </div>
          );
        } else {
          return (
            <div className="sub-heading-padding">
              <p className="sub-heading">
                We use this email to only verify, and to ensure unique entry.
              </p>
            </div>
          );
        }
      }
    }
  }
  print_on_main_page() {
    const {
      draw,
      company_or_institution_name,
      subText,
      survey_type,
      instant_draw,
    } = this.state.data;

    if (survey_type === "retail") {
      if (instant_draw === true) {
        return (
          <p className="text_under_headiong">
            How was your visit today? Yay or Nay? Complete this 40 second survey
            and find out if you're the lucky winner of the $
            {this.state.user_prize_draw} INSTANT DRAW!
          </p>
        );
      } else {
        return (
          <p className="text_under_headiong">
            How was your visit today? Yay or Nay? Complete this 40 second
            survey!
          </p>
        );
      }
    } else {
      return <p className="text_under_headiong">{subText}</p>;
    }
  }

  render_initial_view_for_scanning() {
    return (
      <div className="luup-5">
        <div className="center">
          <div className="noselect">
            <div className="container text-center">
              <div
                style={{
                  paddingTop: 25,
                  paddingBottom: 25,
                }}
              >
                <div>
                  <div className="text-center">
                    <h3 className="heading">Please scan your QR Code</h3>

                    <p className="text_under_headiong">
                      If you have the Luup app: open the app and scan the QR
                      code. Or, click the button below
                    </p>

                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        isClickToPauseDisabled: false,
                        animationData: require("./JSON/QRscan.json"),
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      width="70%"
                      isClickToPauseDisabled={true}
                    />

                    <div className="padding-top">
                      <Button
                        variant="outline-light"
                        onClick={() => {
                          this.setState({
                            qr_code_camera_activate: true,
                            show_main_page_to_scan: false,
                          });
                        }}
                        style={{
                          background: "#301b92",
                          color: "white",
                          paddingRight: 20,
                          paddingLeft: 20,
                          paddingTop: 10,
                          paddingBottom: 10,
                          fontFamily: "Quicksand",
                          fontSize: 16.5,
                        }}
                      >
                        SCAN QR CODE
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render_expired_survey() {
    return (
      <div className="luup-5">
        <div className="center">
          <div className="noselect">
            <div className="container text-center">
              <div
                style={{
                  paddingTop: 25,
                  paddingBottom: 25,
                }}
              >
                <div>
                  <div className="text-center">
                    <h3 className="heading">Survey Expired!</h3>

                    <p className="text_under_headiong">
                      Sorry, this survey is now expired.
                    </p>

                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        isClickToPauseDisabled: false,
                        animationData: require("./JSON/expire.json"),
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      width="50%"
                      isClickToPauseDisabled={true}
                    />

                    <div className="padding-top">
                      <Button
                        variant="outline-light"
                        onClick={() => {
                          window.location = mainwebsite;
                        }}
                        style={{
                          background: "#301b92",
                          color: "white",
                          paddingRight: 30,
                          paddingLeft: 30,
                          paddingTop: 10,
                          paddingBottom: 10,
                          fontFamily: "Quicksand",
                          fontSize: 16.5,
                        }}
                      >
                        GO BACK
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render_wrong_code() {
    return (
      <div className="luup-5">
        <div className="center">
          <div className="noselect">
            <div className="container text-center">
              <div
                style={{
                  paddingTop: 25,
                  paddingBottom: 25,
                }}
              >
                <div>
                  <div className="text-center">
                    <h3 className="heading">Wrong QR Code!</h3>

                    <p className="text_under_headiong">
                      The QR code scanned was incorrect.
                    </p>

                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        isClickToPauseDisabled: false,
                        animationData: require("./JSON/wrong_code.json"),
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      width="50%"
                      isClickToPauseDisabled={true}
                    />

                    <div className="padding-top">
                      <Button
                        variant="outline-light"
                        onClick={() => {
                          window.location = mainwebsite;
                        }}
                        style={{
                          background: "#301b92",
                          color: "white",
                          paddingRight: 30,
                          paddingLeft: 30,
                          paddingTop: 10,
                          paddingBottom: 10,
                          fontFamily: "Quicksand",
                          fontSize: 16.5,
                        }}
                      >
                        GO BACK
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render_scanner() {
    return (
      <div className="luup-5">
        <div className="center">
          <div className="noselect">
            <div className="container text-center">
              <div
                style={{
                  paddingTop: 25,
                  paddingBottom: 25,
                }}
              >
                <div>
                  <div className="text-center">
                    <h3 className="heading">Scan QR Code</h3>

                    <QrReader
                      delay={300}
                      onError={this.handleError}
                      onScan={this.handleScan}
                      style={{ width: "100%", padding: 0 }}
                    />

                    <div className="padding-top">
                      <Button
                        variant="outline-light"
                        onClick={() => {
                          window.location = mainwebsite;
                        }}
                        style={{
                          background: "#301b92",
                          color: "white",
                          paddingRight: 30,
                          paddingLeft: 30,
                          paddingTop: 10,
                          paddingBottom: 10,
                          fontFamily: "Quicksand",
                          fontSize: 16.5,
                        }}
                      >
                        GO BACK
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render_nps_csat_choices(type) {
    const array = this.state.SurveyQuestions[this.state.question];
    const data = _.map(array, (val, uid) => {
      return { ...val };
    });
    var questionsArray = _.map(this.state.SurveyQuestions, (val, uid) => {
      return { ...val, uid };
    });

    var questionnaire_type_conditional = "";
    var choices_conditional = {};
    var question_type = "";

    if (array !== undefined) {
      var question = data[0].question;
      question_type = data[1].questionnaire_type;

      if (this.state.question > 0) {
        var temp_questionaire = questionsArray[this.state.question - 1];
        if (temp_questionaire[1].questionnaire_type !== undefined) {
          if (
            temp_questionaire[1].questionnaire_type === "ConditionalMCQ" ||
            temp_questionaire[1].questionnaire_type === "ConditionalCSAT" ||
            temp_questionaire[1].questionnaire_type === "ConditionalNPS"
          ) {
            question = data[this.state.conditional_choice_selected][0].question;
            questionnaire_type_conditional =
              data[this.state.conditional_choice_selected][1]
                .questionnaire_type;
            question_type = questionnaire_type_conditional;
            choices_conditional =
              data[this.state.conditional_choice_selected][2];
          }
        }
      }

      if (this.state.question < questionsArray.length) {
        if (
          data[1].questionnaire_type === "Open Field" ||
          questionnaire_type_conditional === "Open Field"
        ) {
          return (
            <div>
              <div className="form-group paddingForm">
                <label
                  className="fontStyling_choice"
                  htmlFor={this.state.question}
                >
                  Type your response:
                </label>
                <textarea
                  className="form-control"
                  id={this.state.question}
                  value={this.state.user_answer}
                  rows="3"
                  onChange={(event) => {
                    this.setState({ user_answer: event.target.value });
                  }}
                />
              </div>
              <div style={{ paddingTop: 10 }}>
                <Button
                  variant="outline-light"
                  onClick={() => {
                    this.handleNextButtonPressed({
                      question,
                      type_of_question: question_type, //data[1].questionnaire_type, //"Open Field",
                    });
                  }}
                  style={{
                    background: "#301b92",
                    color: "white",
                    paddingRight: 30,
                    paddingLeft: 30,
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontFamily: "Quicksand",
                    fontSize: 16.5,
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          );
        }

        var data2 = _.map(data[2], (val, uid) => {
          return { ...val, uid };
        });

        if (this.state.question > 0) {
          var temp_questionaire = questionsArray[this.state.question - 1];

          if (temp_questionaire[1].questionnaire_type !== undefined) {
            if (
              temp_questionaire[1].questionnaire_type === "ConditionalMCQ" ||
              temp_questionaire[1].questionnaire_type === "ConditionalCSAT" ||
              temp_questionaire[1].questionnaire_type === "ConditionalNPS"
            ) {
              // console.log(choices_conditional);
              data2 = _.map(choices_conditional, (val, uid) => {
                return { ...val, uid };
              });
            }
          }
        }

        /*************************************************/

        var choices = data2.map((info) => {
          console.log(this.state.choiceSelected);

          // if (question_type === "ConditionalNPS" || question_type === "NPS") {
          //   return null;
          // }
          return (
            <div
              onClick={() => {
                if (
                  question_type === "ConditionalMCQ" ||
                  question_type === "ConditionalCSAT" ||
                  question_type === "ConditionalNPS"
                ) {
                  this.setState({ conditional_choice_selected: info.uid });
                }
                this.handleButtonPressed({
                  question,
                  answer: info.choice,
                  type_of_question: data[1].questionnaire_type, //"MCQ",
                  uid: info.uid,
                });
              }}
              key={info.uid}
              id={info.uid}
              style={{ padding: 4 }}
            >
              <Button
                variant="outline-light"
                style={{ paddingTop: 15, paddingBottom: 15 }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-2">
                      <div className="vertical-center">
                        <div className="circle_outline">
                          {this.renderCircle_checked({
                            uid: info.uid,
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="col-10">
                      <div>
                        <p id={info.uid} className="fontStyling_choice">
                          {info.choice}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Button>
            </div>
          );
        });

        /*************************************************/

        return (
          <div>
            <div>{choices}</div>
            <div style={{ paddingTop: 10 }}>
              <Button
                variant="outline-light"
                onClick={() => {
                  this.handleNextButtonPressed({
                    question,
                    type_of_question: question_type, //data[1].questionnaire_type, //"MCQ",
                  });
                }}
                style={{
                  background: "#301b92",
                  color: "white",
                  paddingRight: 30,
                  paddingLeft: 30,
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontFamily: "Quicksand",
                  fontSize: 16.5,
                }}
              >
                Next
              </Button>
            </div>
          </div>
        );
      }
      return null;
    }
    return null;
  }
  render_survey() {
    var current_question_type = this.check_question_details();

    var show_csat_nps = false;
    if (
      current_question_type === "CSAT" ||
      current_question_type === "NPS" ||
      current_question_type === "ConditionalCSAT" ||
      current_question_type === "ConditionalNPS"
    ) {
      show_csat_nps = true;
    }

    return (
      <div className="luup-5">
        <div>
          <div className="noselect">
            <div className="container text-center">
              <div
                style={{
                  paddingTop: 25,
                  paddingBottom: 25,
                }}
              >
                <div>
                  <div>
                    <div className="left-justified question-padding">
                      {this.render_question()}
                    </div>
                    {show_csat_nps !== true ? (
                      <div className="left-justified answer-padding">
                        {this.render_choices()}
                      </div>
                    ) : (
                      <div>
                        <div>
                          {this.render_nps_csat_choices(current_question_type)}
                        </div>
                      </div>
                    )}

                    <div className="left-justified">
                      {this.render_last_screen()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  progress_bar() {
    if (this.state.exit_questionnaire === true) {
      return (
        <div className="luup-2">
          <div className="luup-3"></div>
        </div>
      );
    }
    return (
      <div>
        {this.state.show_first_page === false ? ( //&&
          //  this.state.unique_entry === true &&
          //  this.state.email_entered === true
          <div className="luup-2-2">
            <Progress
              value={this.state.display_string.current}
              total={this.state.display_string.total}
              color="violet"
            ></Progress>
          </div>
        ) : null}

        <div className="luup-2">
          <div className="luup-3"></div>
        </div>
      </div>
    );
  }
  footer() {
    return (
      <div id="footer">
        <a href="http://www.getluup.com/" imageanchor="1" target="_blank">
          <img src={logo} border="0" id="logo" />
        </a>
      </div>
    );
  }
  render_loading() {
    return (
      <div className="App">
        <div>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: require("./JSON/loading.json"),
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            width="60%"
          />
        </div>
      </div>
    );
  }
  render_first_page() {
    const {
      survey_name,
      unique_entry_required,
      company_or_institution_name,
      survey_type,
      subText,
      exit_option,
      display_name,
    } = this.state.data;
    var name_to_show = "";
    if (survey_type === "retail") {
      name_to_show = display_name;
    } else {
      name_to_show = survey_name;
    }

    var exit = false;
    if (exit_option) {
      if (exit_option === true) {
        exit = true;
      } else {
        exit = false;
      }
    }

    return (
      <div className="luup-5">
        <div className="center">
          <div className="noselect">
            <div className="container text-center">
              <div
                style={{
                  paddingTop: 25,
                  paddingBottom: 25,
                }}
              >
                <div>
                  <div className="text-center">
                    <h3 className="heading">{name_to_show}</h3>

                    {this.print_on_main_page()}

                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        isClickToPauseDisabled: false,
                        animationData: require("./JSON/emojis.json"),
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      width="50%"
                      isClickToPauseDisabled={true}
                    />

                    <div className="padding-top">
                      <Button
                        variant="outline-light"
                        onClick={() => {
                          if (this.state.data.email_required === true) {
                            this.setState({
                              email_required: this.state.data.email_required,
                            });
                          }
                          if (unique_entry_required === false) {
                            this.setState({ unique_entry: false });
                          }
                          this.setState({ show_first_page: false });
                        }}
                        style={{
                          background: "#301b92",
                          color: "white",
                          paddingRight: 30,
                          paddingLeft: 30,
                          paddingTop: 10,
                          paddingBottom: 10,
                          fontFamily: "Quicksand",
                          fontSize: 16.5,
                        }}
                      >
                        BEGIN
                      </Button>
                    </div>
                    {exit === true ? (
                      <div className="padding-top">
                        <Button
                          variant="outline-light"
                          onClick={() => {
                            this.setState({
                              exit_questionnaire: true,
                              show_first_page: false,
                            });

                            const {
                              company_or_institution_name,
                              email_required,
                              general_prize_draw,
                              survey_expiry,
                              survey_name,
                              type_of_email,
                              user_prize_draw,
                              draw,
                              in_person_draw,
                              survey_type,
                              instant_draw_number,
                              type_of_questionnaire,
                            } = this.state.data;
                            var { survey_id, emailAddress, unique_entry } =
                              this.state;

                            //   if (unique_entry === false) {
                            emailAddress = "Email Not Required";
                            //   }
                            var questionnaire_result = {
                              0: {
                                answer: "Did not attend appointment.",
                                question: "Did not attend appointment.",
                                type_of_question: "Open Field",
                              },
                            };

                            var date_local = moment_time_zone()
                              .tz("America/Toronto")
                              .format("MM/DD/YYYY");
                            var time_local = " 12:00";
                            var Date_Time = date_local + time_local;
                            var time = moment_time_zone
                              .tz(
                                Date_Time,
                                "MM/DD/YYYY H:m",
                                "America/Toronto"
                              )
                              .valueOf();

                            var survey_submitted_on = moment_time_zone()
                              .tz("America/Toronto")
                              .format();
                            var {
                              department_database,
                              survey_name_database,
                              company_or_institution_name_database,
                            } = this.state;
                            var transaction_info_array = [
                              {
                                company_or_institution_name,
                                email_required,
                                general_prize_draw,
                                survey_expiry,
                                survey_name,
                                type_of_email,
                                user_prize_draw,
                                survey_id,
                                draw,
                                in_person_draw,
                                department: department_database,
                              },
                            ];
                            var transaction_info = transaction_info_array[0];

                            firebase
                              .database()
                              .ref()
                              .child(
                                `QR_Code/Survey_Submitted/${company_or_institution_name_database}/${department_database}/All_Responses_Combined/all_questionnaire/All`
                              )
                              .push({
                                emailAddress,
                                survey_submitted_on,
                                questionnaire_result,
                                transaction_info,
                                user: "NOT A USER",
                              });
                          }}
                          style={{
                            background: "#B00020",
                            color: "white",
                            paddingRight: 30,
                            paddingLeft: 30,
                            paddingTop: 10,
                            paddingBottom: 10,
                            fontFamily: "Quicksand",
                            fontSize: 16.5,
                          }}
                        >
                          Did not attend appointment
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render_email_page() {
    return (
      <div className="luup-5">
        <div className="center">
          <div className="noselect">
            <div className="container text-center">
              <div
                style={{
                  paddingTop: 25,
                  paddingBottom: 25,
                }}
              >
                <div>
                  <form
                    role="form"
                    onSubmit={this.handleSubmit_with_email.bind(this)}
                  >
                    <div className="container text-center">
                      <h3 className="heading">Email Address</h3>
                      <div>
                        <div className="left-justified">
                          <div>
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Enter email"
                                required
                                onChange={this.handleEmail}
                              />

                              {this.print_email_bottom_line()}
                            </div>

                            <button
                              type="submit"
                              style={{
                                background: "#301b92",
                                color: "white",
                                paddingRight: 30,
                                paddingLeft: 30,
                                paddingTop: 10,
                                paddingBottom: 10,
                                fontFamily: "Quicksand",
                                fontSize: 16.5,
                              }}
                              className="btn btn-outline-primary"
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  exit_questionnaire() {
    return (
      <div className="luup-5">
        <div className="center">
          <div className="noselect">
            <div className="container text-center">
              <div
                style={{
                  paddingTop: 25,
                  paddingBottom: 25,
                }}
              >
                <div>
                  <div className="text-center">
                    <h3 className="heading">Thank you!</h3>

                    <Lottie
                      options={{
                        loop: false,
                        autoplay: true,
                        animationData: require("./JSON/success.json"),
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      width="55%"
                    />

                    <div className="padding-top">
                      {this.render_last_screen_button()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  view_showing_logic() {
    if (this.state.show_content === false) {
      if (this.state.expired === true) {
        return <div>{this.render_expired_survey()}</div>;
      }
      return <div>{this.render_wrong_code()}</div>;
    } else {
      if (this.state.show_main_page_to_scan === true) {
        return <div>{this.render_initial_view_for_scanning()}</div>;
      } else if (this.state.qr_code_camera_activate === true) {
        return <div>{this.render_scanner()}</div>;
      } else {
        var questionsArray = _.map(this.state.SurveyQuestions, (val, uid) => {
          return { ...val, uid };
        });

        if (questionsArray.length === 0) {
          return <div>{this.render_loading()}</div>;
        } else if (this.state.show_first_page === true) {
          return <div>{this.render_first_page()}</div>;
        } else if (
          this.state.show_first_page === false &&
          this.state.exit_questionnaire === true
        ) {
          return <div>{this.exit_questionnaire()}</div>;
        } else if (
          this.state.show_first_page === false &&
          (this.state.email_required === true ||
            this.state.unique_entry === true) &&
          this.state.email_entered === false
        ) {
          return <div>{this.render_email_page()}</div>;
        }

        return <div>{this.render_survey()}</div>;
      }
    }
  }

  render() {
    if (this.state.show_content === null) {
      return <div>{this.render_loading()}</div>;
    }

    return (
      <div className="App">
        <div
          id="luup-embed-container"
          className=""
          //
        >
          <div id="luup-embed">
            <div
              className="luup-4 noselect"
              //
            >
              <div>
                <Spring
                  config={{ tension: 400, friction: 60 }}
                  from={{
                    opacity: 0,
                    marginTop: -400,
                  }}
                  to={{
                    opacity: 1,
                    marginTop: 0,
                  }}
                >
                  {(props) => (
                    <div style={props}>
                      {this.view_showing_logic()}
                      {this.progress_bar()}
                    </div>
                  )}
                </Spring>
              </div>
            </div>
          </div>
          {this.footer()}
        </div>
      </div>
    );
  }
}

export default App;
